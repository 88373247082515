.switch-form {
    &>.form-title {
        text-align: left;
        margin-bottom: 30px;
    }

    .form-button {
        width: 100%;
    }

    .form-item-name {
        text-align: left;
    }
}

.header-title {
    text-align: left;
    margin-bottom: 64px;
}

.notification {
    max-width: 80%;
    min-width: 40%;
    position: fixed;
    right: 32px;
    top: 80px;
    text-align: left;
    overflow: hidden;
    z-index: 10000;
    opacity: 0.9;
}