.container {
  margin-top: 64px;
}

.app-background {
  position: absolute;
  height: 100%;
  width: 80px;
  background-color: #4a5b94;
  transition: width 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.app-link {
  &:hover {
    .app-title {
      text-decoration: none;
    }

    .app-background {
      width: 100%;
    }
  }
}

.app-card {
  cursor: pointer;
  position: relative;
  transition: all 0.5s;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;

  display: flex;
  width: 100%;
  height: 100%;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: scale(1.03);

    .app-title {
      color: white;
    }

    .app-name {
      color: white;
    }

    .app-content {
      color: white;
    }
  }
}

.app-content {
  font-size: 14px;
  margin-bottom: 0;
  color: #333;
  opacity: 0.8;
  line-height: 1.1;
}

.cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: 1fr;
}

.app-title {
  text-align: left;
  z-index: 2;
  padding: 14px 10px 14px 0;
}

.app-icon-place {
  z-index: 2;
  display: grid;
  place-items: center;
  padding: 16px;
  margin-right: 16px;
}

.app-icon {
  width: 48px;
  height: auto;
  fill: white;
}

.app-name {
  margin-bottom: 7px;
  font-size: 18px;
}

.nav-tabs {
  margin-bottom: 16px;
  border: none;
}

.no-apps {
  text-align: left;
  color: gray;
  font-size: 14px;
}

.tab-button {
  height: 48px;
  border: none;
  border-bottom: solid 3px #2c76ff;
  cursor: pointer;
}
