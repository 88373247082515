amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
}

:root {
  --amplify-primary-color: #24356d;
  --amplify-primary-shade: #202f5f;
  --amplify-primary-tint: #202f5f;
  --ecs-blue: #24356d;
  --ecs-mid-blue: #98aed5;
  --ecs-dark-blue: #111f4f;
  --ecs-light-blue: #cbd6ea;
  --ecs-red: #bd2440;
}
