.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h2,
h3,
h5,
h4,
h6,
h7 {
  color: #24356d;
}

body {
  background-color: #f3f2f1;
}

.ecs-clip {
  position: fixed;
  right: 20px;
  bottom: 32px;
  z-index: -1;

  .ecs-logo {
    opacity: 0.1;
    width: 400px;
    height: auto;
  }
}

a:hover {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
