.navbar-logo {
  height: 44px;
  width: auto;
  margin-right: 64px;
}

.navbar {
  background-color: var(--ecs-blue);
  padding: 16px;
  display: flex;
  align-items: center;
}

.search-input:hover {
  background-color: white;
}

.search-input-div {
  position: relative;
  display: flex;
  align-items: baseline;

  .search-icon {
    position: absolute;
    top: 50%;
    fill: var(--ecs-light-blue);
    transform: translateY(-50%) rotate(90deg);
    -webkit-transform: translateY(-50%) rotate(90deg);
    -moz-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    -o-transform: translateY(-50%) rotate(90deg);
  }

  .search-input {
    width: 400px;
    padding: 8px 8px 8px 30px;
    border: none;
    border-bottom: solid 1px var(--ecs-light-blue);
    background-color: transparent;
    color: white;

    &:focus {
      border-color: white;
      outline: none;

      .search-icon {
        fill: white;
      }
    }

    &::placeholder {
      color: var(--ecs-mid-blue);
    }
  }
}

.form-section {
  margin-bottom: 50px;
}

.dp-section {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--ecs-light-blue);
  box-shadow: 0 0 5px white;

  display: grid;
  place-items: center;
  cursor: pointer;

  &:hover {
    background-color: white;
  }
}

.dp {
  width: 32px;
  height: auto;
  fill: var(--ecs-blue);
}

.right-section {
  width: 100%;
}

.user-actions {
  display: flex;
  align-content: center;
}

.user-details {
  padding-right: 16px;
  border-right: 1px solid var(--ecs-mid-blue);
  margin-right: 16px;
  text-align: right;
  line-height: 1.1;
}

.user-name {
  font-size: 16px;
  font-weight: bold;
  color: var(--ecs-mid-blue);
}

.user-email {
  color: var(--ecs-light-blue);
}

.logout {
  padding: 5px 9px 5px 10px;
  border-radius: 5px;
  background-color: var(--ecs-red);
  color: var(--ecs-light-blue);
  cursor: pointer;
  border: 1px solid var(--ecs-light-blue);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    border-color: white;
    color: white;
  }
}

@media screen and (max-width: 960px) {
  .search-input-div {
    flex: 1;
    .search-input {
      width: 100%;
    }
  }
}
